import React from "react"
import { Link } from "gatsby"
import { ArrowLeft, ArrowRight } from "./Arrows"

import "./style.scss"

const PlaceHolder = () => <div className="placeholder-div" />

const ArrowNavBar = props => {
  const { previous, next } = props

  return (
    <div className={`arrow-nav-div ${props.hasArrows && "none-all"}`}>
      {previous ? (
        <Link to={previous} className="arrow-link link-left">
          <ArrowLeft />
          <p>previous</p>
        </Link>
      ) : (
        <PlaceHolder />
      )}

      <Link to="/">
        <p>home</p>
      </Link>

      {next ? (
        <Link to={next} className="arrow-link link-right">
          <p>next</p>
          <ArrowRight />
        </Link>
      ) : (
        <PlaceHolder />
      )}
    </div>
  )
}

export default ArrowNavBar
