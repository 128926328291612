import React from "react"

export const ArrowRight = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="10px"
      viewBox="0 0 480 99"
    >
      <title id="arrowTitle">Arrow pointing right</title>
      <desc id="arrowDescription">
        Arrow pointing right which turns pink when active
      </desc>
      <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)">
        <path
          d="M267,810c-30-24-47-74-40-114c3-15,38-61,78-104l73-77l-73-76c-76-80-89-109-74-165c14-47,52-74,107-74c46,0,47,1,179,133
          l133,133v49v49L517,697C385,829,384,830,338,830C308,830,284,823,267,810z"
        />
        <path
          d="M694,819c-27-14-54-60-54-94c0-39,15-63,91-140l69-71l-66-64c-74-73-94-105-94-151s56-99,104-99c55,0,80,18,198,137
          c167,169,168,186,5,351c-62,63-124,120-139,128C777,832,725,833,694,819z"
        />
        <path
          d="M1089,801c-24-24-29-38-29-76c0-46,3-51,79-129l80-81l-80-81c-76-78-79-83-79-128c0-39,5-53,29-77s38-29,77-29
          c46,0,49,2,154,105l106,105h1402h1403l-23-31c-16-21-23-45-23-76c0-38,5-49,33-74c26-23,42-29,78-29c45,0,48,2,166,118
          c124,121,148,153,148,197s-24,75-148,198c-117,115-119,117-165,117c-37,0-53-6-79-29c-28-25-33-36-33-74c0-31,7-55,23-76l23-31
          H2828H1426l-106,105c-105,103-108,105-154,105C1127,830,1113,825,1089,801z"
        />
      </g>
    </svg>
  )
}

export const ArrowLeft = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="10em"
      height="1em"
      viewBox="0 0 480 99"
    >
      <title id="arrowTitle">Arrow pointing left</title>
      <desc id="arrowDescription">
        Arrow pointing left which turns pink when active
      </desc>
      <g transform="translate(0.000000,99.000000) scale(0.100000,-0.100000)">
        <path
          d="M4568.4,220c30,24,47,74,40,114c-3,15-38,61-78,104l-73,77l73,76c76,80,89,109,74,165c-14,47-52,74-107,74
		c-46,0-47-1-179-133l-133-133v-49v-49l133-133c132-132,133-133,179-133C4527.4,200,4551.4,207,4568.4,220z"
        />
        <path
          d="M4141.4,211c27,14,54,60,54,94c0,39-15,63-91,140l-69,71l66,64c74,73,94,105,94,151s-56,99-104,99c-55,0-80-18-198-137
		c-167-169-168-186-5-351c62-63,124-120,139-128C4058.4,198,4110.4,197,4141.4,211z"
        />
        <path
          d="M3746.4,229c24,24,29,38,29,76c0,46-3,51-79,129l-80,81l80,81c76,78,79,83,79,128c0,39-5,53-29,77s-38,29-77,29
		c-46,0-49-2-154-105l-106-105h-1402h-1403l23,31c16,21,23,45,23,76c0,38-5,49-33,74c-26,23-42,29-78,29c-45,0-48-2-166-118
		c-124-121-148-153-148-197s24-75,148-198c117-115,119-117,165-117c37,0,53,6,79,29c28,25,33,36,33,74c0,31-7,55-23,76l-23,31h1403
		h1402l106-105c105-103,108-105,154-105C3708.4,200,3722.4,205,3746.4,229z"
        />
      </g>
    </svg>
  )
}
