import React from "react"

import PageLayout from "../components/pageLayout/index"
import SEOHelmet from "../components/SEOHelmet/index"

const NotFoundPage = () => (
  <PageLayout hasArrows={false}>
    <SEOHelmet title="404: Not found" />
    <div className="page-div-padding page-padding">
      <h2 className="page-div-header">NOT FOUND</h2>
      <div className="not-found-div">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </PageLayout>
)

export default NotFoundPage
