import React, { useEffect, useRef, useState } from "react"
import "../../style/index"
import "./style.scss"
import Header from "../Header/index"
import Footer from "../Footer/index"
import ArrowNavBar from "../ArrowNavBar/index"
import CookieConsentBanner from "../CookieConsentBanner/index"

const PageLayout = props => {
  const [isSticky, setSticky] = useState(false)

  const ref = useRef(null)

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <>
      <div className={`sticky-wrapper${isSticky ? " sticky" : ""}`} ref={ref}>
        <Header opacity={1} />
      </div>
      <div
        className={`main-div ${
          props.hasArrows ? " main-height-arrows" : "main-height-no-arrows"
        }`}
      >
        <main> {props.children}</main>
      </div>
      <ArrowNavBar
        previous={props.previous}
        next={props.next}
        hasArrows={props.hasArrows}
        noPrevious={props.noPrevious}
        noNext={props.noNext}
      />
      <Footer />
      <CookieConsentBanner />
    </>
  )
}

export default PageLayout
